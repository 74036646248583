<template>
  <div>
    <div v-if="hasNonDateFilters()" class="flex-row margin-top" style="flex-wrap: wrap">
      <p class="body-1 vertical-flex-center">Filters:</p>
      <div v-for="(filter, i) in filters()" v-bind:key="i" class="flex-row filter margin-left-025">
        <div class="flex-center flex-row">
          <p class="body-2-bold vertical-flex-center">{{ filter.title }}:</p>
          <p class="body-2 vertical-flex-center margin-left-025">{{ filter.value }}</p>
          <button
            class="margin-left-025 vertical-flex-center flex-row no-button-style"
            v-on:click="removeFilterButtonPressed(filter)"
          >
            <img src="/assets/img/icon-close.svg" class="vertical-flex-center" />
          </button>
        </div>
      </div>
      <div class="margin-left-auto margin-right flex-row clear-all pointer-cursor" v-on:click="clearFilters()">
        <p class="body-2 vertical-flex-center margin-left-025">Clear all filters</p>
        <button class="margin-left-025 vertical-flex-center flex-row no-button-style">
          <img src="/assets/img/icon-close.svg" class="vertical-flex-center" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { calculateFilters } from "../../../helpers/v2/filterHelpers";

export default {
  name: "FilterBar",
  props: {
    customFilters: {
      type: Object,
      default: null
    }
  },
  methods: {
    hasNonDateFilters: function () {
      if (this.customFilters) {
        return (
          Object.entries(this.customFilters).filter(entry => {
            return entry[0] != "quick_search";
          }).length > 0
        );
      }
      for (const [key] of Object.entries(this.getFilters)) {
        if (key != "date_time_created_gt" && key != "date_time_created_lt" && key != "quick_search") {
          return true;
        }
      }
      return false;
    },
    removeFilterButtonPressed: function (filter) {
      if (this.customFilters) {
        this.$emit("removeFilter", filter);
      } else {
        this.removeFilter({ filter: filter });
      }
    },
    clearFilters: function () {
      this.removeAllFilters();
    },
    filters: function () {
      if (this.customFilters) {
        return calculateFilters(this.customFilters, this.dealerships, this.dealers);
      } else {
        return calculateFilters(this.getFilters, this.dealerships, this.dealers);
      }
    },
    ...mapActions({
      removeFilter: "formStore/removeFilter",
      removeAllFilters: "formStore/removeAllFilters"
    })
  },
  computed: {
    ...mapGetters({
      getFilters: "formStore/getFilters",
      dealers: "dealershipStore/dealers",
      dealerships: "dealershipStore/dealerships"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.filter {
  background-color: $YELLOW_COLOR;
  height: 40px;
  border-radius: 40px;
  padding: 8px 12px;
  border: none;
}

.clear-all {
  @extend .filter;

  background-color: #ffffff;
  border: 2px solid black;
}
</style>
