<template>
  <div>
    <div id="tables-page">
      <h1 class="h1-title">Custom Reports</h1>
      <div v-if="reports.length == 0" class="flex-row">
        <div class="flex-column horizontal-flex-center margin-bottom-4 margin-top-4">
          <img class="splash-img horizontal-flex-center" src="/assets/img/splash1.jpg" />
          <h2 class="h2-title margin-top-1-05 horizontal-flex-center">Welcome To Custom Reports</h2>
          <p class="body-1 margin-top-05">
            You don't seem to have any custom reports saved. Set some filters in the Appraisals Tab and save them to
            reuse them here at any time.
          </p>
        </div>
      </div>
      <div v-if="reports.length > 0">
        <search-bar class="margin-right margin-top-2" availableFilterMode="dealership">
          <div class="position-relative">
            <secondary-button
              v-on:click="showReportDropdown()"
              class="margin-left-05"
              title="Apply Report"
              icon="assets/img/icon-plus.svg"
              :selected="showReports"
            />
            <div v-if="showReports" class="dropdown shadow body-2 padding-vertical-025 min-width-120">
              <div
                class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                v-for="report in reports"
                v-bind:key="report"
              >
                <p v-on:click="selectReport(report)">{{ report.title }}</p>
                <img
                  v-on:click="deleteSelectedReportFilter(report)"
                  src="assets/img/icon-trash.svg"
                  class="margin-left-auto pointer-cursor"
                />
              </div>
            </div>
          </div>
          <div class="position-relative">
            <secondary-button
              v-on:click="showFieldsDropdown()"
              class="margin-left-05"
              title="Apply Fields"
              icon="assets/img/icon-plus.svg"
              :selected="showFields"
            />
            <div v-if="showFields" class="dropdown shadow body-2 padding-vertical-025 min-width-320">
              <div class="flex-row">
                <div class="flex-column flex-grow margin-top-05 margin-left-05">
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.dealership" type="checkbox" />
                    <label>Dealership</label>
                  </div>
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.dealer" type="checkbox" />
                    <label>User</label>
                  </div>
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.intention" type="checkbox" />
                    <label>Intention</label>
                  </div>
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.eta" type="checkbox" />
                    <label>ETA</label>
                  </div>
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.traded" type="checkbox" />
                    <label>Trade Status</label>
                  </div>
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.status" type="checkbox" />
                    <label>Status</label>
                  </div>
                </div>
                <div class="flex-column flex-grow margin-left-05 margin-top-05">
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.dateCreated" type="checkbox" />
                    <label>Date Created</label>
                  </div>
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.mileage" type="checkbox" />
                    <label>Mileage</label>
                  </div>
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.colour" type="checkbox" />
                    <label>Colour</label>
                  </div>
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.finalOffer" type="checkbox" />
                    <label>Final Offer</label>
                  </div>
                  <div class="margin-bottom-025">
                    <input class="margin-right-025" v-model="toggledFields.tender" type="checkbox" />
                    <label>Tender</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <secondary-button
            class="min-width-120 margin-left-05 vertical-flex-center"
            :title="downloading ? 'Downloading...' : 'Download'"
            icon="/assets/img/icon-download.svg"
            v-on:click="download()"
          />
        </search-bar>
        <filter-bar />
        <div class="results-table-container">
          <results-table
            class="margin-top"
            availableSearchMode="custom"
            :openInNewWindow="true"
            :customKeys="getTableKeys()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchReportFilters, deleteReportFilters } from "../../../api/Reports.js";
import { mapActions } from "vuex";

import { endpoints } from "../../../api/axiosHandler";
import SearchBar from "../../../components/Views/v2/SearchBar.vue";
import FilterBar from "../../../components/Views/v2/FilterBar.vue";
import SecondaryButton from "../../../components/Buttons/v2/SecondaryButton.vue";
import ResultsTable from "../../../components/Views/v2/ResultsTable.vue";

export default {
  name: "Tables",
  components: {
    SecondaryButton,
    ResultsTable,

    SearchBar,
    FilterBar
  },
  data: function () {
    return {
      reports: [],
      downloading: false,

      toggledFields: {
        dealership: false,
        dealer: false,
        intention: false,
        eta: false,
        traded: false,
        status: false,
        dateCreated: false,
        mileage: false,
        colour: false,
        finalOffer: false,
        tender: false
      },

      showReports: false,
      showFields: false
    };
  },
  methods: {
    deleteSelectedReportFilter: function (report) {
      var confirmation = confirm("Are you sure you want to delete this report?\n" + report.title);
      if (confirmation) {
        deleteReportFilters(report)
          .then(() => {
            this.reports = this.reports.filter(reportFilter => reportFilter != report);
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },

    selectReport: function (report) {
      this.closeDropdowns();
      this.removeAllFilters();
      let unsplitFilters = report.filter_string.substring(1);
      let splitFilters = unsplitFilters.split("&");
      splitFilters.forEach(filter => {
        if (filter) {
          let splitFilter = filter.split("=");
          if (splitFilter.length == 2) {
            this.addFilter({
              filter: {
                key: splitFilter[0],
                value: splitFilter[1]
              }
            });
          }
        }
      });
      this.getForms();
    },
    closeDropdowns: function () {
      this.showReports = false;
      this.showFields = false;
    },
    showReportDropdown: function () {
      if (this.showReports) {
        this.closeDropdowns();
      } else {
        this.closeDropdowns();
        this.showReports = true;
      }
    },
    showFieldsDropdown: function () {
      if (this.showFields) {
        this.closeDropdowns();
      } else {
        this.closeDropdowns();
        this.showFields = true;
      }
    },
    getTableKeys: function () {
      var toReturn = [];

      toReturn.push("car_registration_number");
      toReturn.push("car_make");
      toReturn.push("car_model");
      toReturn.push("car_model_year");
      toReturn.push("car_line");

      if (this.toggledFields.dealership) {
        toReturn.push("dealership");
      }
      if (this.toggledFields.dealer) {
        toReturn.push("dealer");
      }
      if (this.toggledFields.intention) {
        toReturn.push("traded_purpose");
      }
      if (this.toggledFields.eta) {
        toReturn.push("traded_eta");
      }
      if (this.toggledFields.traded) {
        toReturn.push("winning_offer");
      }
      if (this.toggledFields.status) {
        toReturn.push("appraisal_status");
      }
      if (this.toggledFields.dateCreated) {
        toReturn.push("date_time_created");
      }
      if (this.toggledFields.mileage) {
        toReturn.push("car_mileage");
      }
      if (this.toggledFields.colour) {
        toReturn.push("car_colour");
      }
      if (this.toggledFields.finalOffer) {
        toReturn.push("final_offer");
      }
      if (this.toggledFields.tender) {
        toReturn.push("tender");
      }

      return toReturn;
    },
    download: function () {
      if (this.downloading) {
        return;
      }
      this.downloading = true;
      this.downloadCSV(endpoints.form + "download-csv/").then(() => {
        this.downloading = false;
      });
    },
    ...mapActions({
      getForms: "formStore/getForms",
      removeAllFilters: "formStore/removeAllFilters",
      addFilter: "formStore/addFilter",
      addError: "errorStore/addError",
      downloadCSV: "formStore/downloadCSV"
    })
  },
  mounted: function () {
    this.removeAllFilters();
    fetchReportFilters()
      .then(response => {
        this.reports = response;
      })
      .catch(error => {
        this.addError(error);
      });
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#tables-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}

.dropdown {
  left: auto;
}

.splash-img {
  width: 326px;
  height: 170px;
  object-fit: cover;
}
</style>
