<template>
  <div>
    <table v-if="currentForms().length > 0" class="results-table">
      <tr class="header">
        <th v-if="checkBox">
          <input v-model="selectAllCheckbox" type="checkbox" name="" id="" />
        </th>
        <th class="no-mobile" style="width: 60px">Photo</th>
        <th
          :class="{ 'pointer-cursor': formData.sortFunc }"
          v-for="formData in formData()"
          v-bind:key="formData"
          v-on:click="setSortFunc(formData.sortFunc)"
        >
          {{ formData.title }} {{ sortFunc == formData.sortFunc ? (reversed ? "▼" : "▲") : "" }}
        </th>
      </tr>
      <tr class="data-row selectable" v-for="form in currentForms()" v-bind:key="form.id">
        <td class="default-cursor" v-if="checkBox">
          <input type="checkbox" name="" id="" v-model="selectedForms" :value="form" />
        </td>
        <td class="no-mobile default-cursor">
          <div class="img-container">
            <img v-if="form.thumbnail" :src="form.thumbnail" class="thumbnail" />
          </div>
        </td>
        <td v-on:click="selectedForm(form.id)" v-for="formData in formData()" v-bind:key="formData">
          <p v-if="formData.type == 'raw'">{{ form[formData.key] }}</p>
          <p v-if="formData.type == 'number'">
            {{ form[formData.key] ? Number(form[formData.key]).toLocaleString() : "—" }}
          </p>
          <p v-if="formData.type == 'date'">{{ formatDate(form[formData.key]) }}</p>
          <p v-if="formData.type == 'complex'">{{ formData.content(form) }}</p>
          <div class="flex-row" v-if="formData.type == 'indicator'">
            <div class="margin-right-025" :class="formData.indicatorClass(form)"></div>
            <p>{{ formData.content(form) }}</p>
          </div>
        </td>
      </tr>
    </table>
    <paginator-bar
      v-if="showPaginatorBar"
      v-model:page="page"
      :pageBounds="pageBounds()"
      :loadedItemsCount="loadedFormsCount()"
      :loading="getLoadingForms"
      :hasMorePages="getNextUrl != null"
      :totalItemsCount="totalFormsCount"
      @loadMore="getNextForms()"
    />
  </div>
</template>

<script>
import { dateToDayMonthYearReadableString } from "../../../helpers/utilityHelpers.js";
import { mapState, mapActions, mapGetters } from "vuex";
import {
  availableKeysForSearchPageForDealership,
  availableKeysForSearchPageForPendingArrivals,
  availableKeysForSearchPageForReseller,
  availableKeysForSearchPageForTender,
  availableKeysForSearchPageForTenderTheoretical,
  availableKeysForSearchPageForTenderMeets,
  availableKeysForRemarketing,
  availableKeysForMyMarket,
  availableKeysForReports,
  options
} from "../../../helpers/v2/resultsTableHelpers.js";

import PaginatorBar from "../../Common/PaginatorBar.vue";
import { pageBounds } from "../../../helpers/v2/utilityHelpers";

export default {
  name: "ResultsTable",
  components: {
    PaginatorBar
  },
  props: {
    checkBox: {
      type: Boolean,
      default: false
    },
    openInNewWindow: {
      type: Boolean,
      default: false
    },
    availableSearchMode: {
      type: String,
      default: ""
    },
    showPaginatorBar: {
      type: Boolean,
      default: true
    },
    showAllForms: {
      type: Boolean,
      default: false
    },
    customKeys: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      selectAllCheckbox: false,
      selectedForms: [],
      loading: false,
      downloading: false,
      page: 1,
      sortFunc: (a, b) => {
        return a.date_time_created < b.date_time_created ? 1 : -1;
      },
      reversed: false
    };
  },
  methods: {
    formData: function () {
      var availableKeys = [];

      switch (this.availableSearchMode) {
        case "dealership":
          availableKeys = availableKeysForSearchPageForDealership(this.currentDealership, this.currentDealer);
          break;
        case "pending_arrivals":
          availableKeys = availableKeysForSearchPageForPendingArrivals(this.currentDealer);
          break;
        case "tender":
          availableKeys = availableKeysForSearchPageForTender(this.currentDealer);
          break;
        case "tender_theoretical":
          availableKeys = availableKeysForSearchPageForTenderTheoretical(this.currentDealer);
          break;
        case "tender_meets":
          availableKeys = availableKeysForSearchPageForTenderMeets(this.currentDealer);
          break;
        case "reseller":
          availableKeys = availableKeysForSearchPageForReseller();
          break;
        case "remarketing":
          availableKeys = availableKeysForRemarketing();
          break;
        case "my_market":
          availableKeys = availableKeysForMyMarket();
          break;
        case "reports":
          availableKeys = availableKeysForReports();
          break;
        case "custom":
          availableKeys = this.customKeys;
          break;
        default:
          break;
      }

      return options.filter(option => {
        return (
          availableKeys.filter(availableKey => {
            return availableKey == option.key;
          }).length > 0
        );
      });
    },
    selectedForm: function (id) {
      if (this.openInNewWindow) {
        let routeData = this.$router.resolve({
          name: "Form",
          params: { id: id }
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: "Form",
          params: { id: id }
        });
      }
    },
    formatDate(dateString) {
      return dateToDayMonthYearReadableString(dateString);
    },
    pageBounds() {
      return pageBounds(this.page);
    },
    currentForms() {
      if (this.showAllForms) {
        return this.forms().sort((a, b) => {
          return this.sortFunc(a, b) * (this.reversed ? -1 : 1);
        });
      }
      let bounds = this.pageBounds();
      return this.forms()
        .slice(bounds.lowBound, bounds.highBound)
        .sort((a, b) => {
          return this.sortFunc(a, b) * (this.reversed ? -1 : 1);
        });
    },
    getNextForms() {
      this.$emit("loadedMoreForms");
      this.getMoreForms(this);
    },
    loadedFormsCount: function () {
      return this.forms().length;
    },
    numPages: function () {
      return Math.floor(this.loadedFormsCount() / 20);
    },
    setSortFunc: function (newSortFunc) {
      if (newSortFunc) {
        if (newSortFunc == this.sortFunc) {
          this.reversed = !this.reversed;
        } else {
          this.sortFunc = newSortFunc;
        }
      }
    },
    ...mapActions({
      getMoreForms: "formStore/getMoreForms"
    })
  },
  computed: {
    ...mapState({
      totalFormsCount: state => state.formStore.totalFormsCount
    }),
    ...mapGetters({
      getNextUrl: "formStore/getNextUrl",
      forms: "formStore/getForms",
      getLoadingForms: "formStore/getLoadingForms",
      currentDealership: "dealershipStore/currentDealership",
      currentDealer: "dealershipStore/currentDealer"
    })
  },
  watch: {
    selectedForms: function () {
      this.$emit("formChecked", this.selectedForms);
    },
    selectAllCheckbox: function (newVal) {
      if (newVal) {
        this.selectedForms = this.forms();
      } else {
        this.selectedForms = [];
      }
      this.$emit("formChecked", this.selectedForms);
    },
    "$store.state.formStore.filters": {
      deep: true,
      handler() {
        this.page = 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.img-container {
  position: relative;
  height: 48px;
  width: 48px;

  .thumbnail {
    position: absolute;
    height: 48px;
    width: 48px;

    transition: 0.3s;

    &:hover {
      height: 160px;
      width: 160px;
      z-index: 999;
    }
  }
}
</style>
